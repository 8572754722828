import * as React from 'react';
import {useEffect, useState} from "react";
import {MoneyInput} from "./Form/MoneyInput";
import {MoneyInputComas} from "./Form/MoneyInputComas";

interface Props {
ARV:string,

    stepFourState:stepFourContract,
    setStepFourState:(data:stepFourContract)=>void,

    stepFourCalcState:stepFourCalcContract,
    setStepFourCalcState:(data:stepFourCalcContract)=>void,

updateARV:(data:string)=>void,
    totalestimate:number,
    settotalestimate:(data:number)=>void,

    estimateselectpercent:number,
    setestimateselectpercent:(data:number)=>void,

    discountSelectpercent:number,
    setdiscountSelectpercent:(data:number)=>void,

    sqfoot:number,
    setsqfoot:(data:number)=>void,

    discountSelect:number,
    setdiscountSelect:(data:number)=>void,

    fees:number,
    setfees:(data:number)=>void,
};

interface stepFourCalcContract {
    source_value: { name: string, value: string }[],

}

interface stepFourContract{
    datestate:string,
    date: { month: string, day: string, year: string },
    earnest:string,
    methodOne:string,
    methodTwo:string,
    purchase_price:string
}
interface stepThreeContract{
    source_value:{name:string,value:string}[],
}


const initialStepThreeState:stepThreeContract={
    source_value:[
        {name:"",value:"0"},
    ],

};
export function StepFour(props: Props) {

    const [number,setnumber]=useState([{}]);


    const [totalestimate,settotalestimate]=useState(0);


    const [earnesterror,setearnesterror]=useState(false);
    const [priceerror,setpriceerror]=useState(false);




    const [Repairssq ,setRepairssq ]=useState(0);
    const [result ,setresult ]=useState(0);



    const [stepThreeState,setStepThreeState] =useState<stepThreeContract>(initialStepThreeState);

    function commas(number: number) {
        console.log(number);
        if(number===0)
            return '0';
        if(number<0)
            number=number*-1;
        if(number===0)
            return '0';
        let n = number.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        if (n.slice(-2).charAt(0) === ".")
            n = n + '0';
        return n;
    }
    useEffect(()=>{
        console.log("run");
        let newresult=Number(props.ARV);
        newresult=Number(props.ARV)*(props.discountSelectpercent /100);
        console.log(newresult);

        let cal=Number(props.estimateselectpercent)*props.sqfoot;
        newresult=newresult-cal;

        // props.settotalestimate(Number(props.estimateselectpercent)*props.sqfoot);
        props.settotalestimate(Number(props.estimateselectpercent)*props.sqfoot);

        props.settotalestimate(Number(props.estimateselectpercent)*props.sqfoot);
        if(isNaN(props.totalestimate))
            props.settotalestimate(0);

        if(!isNaN(newresult))
            setresult(newresult);
        // let newresult=Number(props.ARV)*(props.discountSelectpercent /100);
        // setresult(newresult);

    },[props.estimateselectpercent,props.discountSelectpercent,props.sqfoot])


    function totalhandleChange(e:any) {


        settotalestimate(e.target.value);
        props.settotalestimate(e.target.value);
        let newresult=Number(props.ARV);
        newresult=Number(props.ARV)*(props.discountSelectpercent /100);
        console.log(newresult);

        let cal=e.target.value;
        newresult=newresult-cal;
        newresult=newresult-props.fees;


        if(!isNaN(newresult))
        setresult(newresult);

    }
    function totalhandleChangeNew(e:any) {


        settotalestimate(e);
        props.settotalestimate(e);
        let newresult=Number(props.ARV);
        newresult=Number(props.ARV)*(props.discountSelectpercent /100);
        console.log(newresult);

        let cal:any=e;
        newresult=newresult-cal;
        newresult=newresult-props.fees;


        if(!isNaN(newresult))
        setresult(newresult);

    }

    function feehandleChange(e:any) {
        props.setfees(e.target.value);
        let newresult=Number(props.ARV);
        console.log(newresult);

        let cal=totalestimate;

        newresult=Number(props.ARV)*(props.discountSelectpercent /100);

        newresult=newresult-totalestimate;
        newresult=newresult-props.fees;


        if(!isNaN(newresult))
            setresult(newresult);

    }

    function feehandleChangeNew(e:any) {
        props.setfees(e);
        let newresult=Number(props.ARV);
        console.log(newresult);

        let cal=totalestimate;

        newresult=Number(props.ARV)*(props.discountSelectpercent /100);

        newresult=newresult-totalestimate;
        newresult=newresult-props.fees;


        if(!isNaN(newresult))
            setresult(newresult);

    }



    function discounthandleChange(event:any) {
        props.setdiscountSelectpercent(event.target.value);
        console.log(event.target.value);
    }


    function seconddiscounthandleChange(event:any) {
        props.setdiscountSelect(event.target.value);
    }




    function selecthandleChange(event:any) {
        props.setestimateselectpercent(event.target.value);
    }




    return (

        <div className="flex w-full   space-x-3">

            <form action="#" method="POST" className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 w-full lg:w-3/4 xl:w-3/6   md:m-auto  pl-4 pr-4  ">
                <div className="sm:col-span-3 mt-12 mb-2" >

                    <h3 className="text-3xl"> Cash Offer Calculator</h3> <br/>
                    <div className="c-field c-col-1 c-sml-col-1 c-span-24 c-sml-span-12">
                        <div className="text-lg  font-bold text-blue-600 "><h3><span style={{textDecoration: ""}}>Method 1: ARV x Discount (70%) - Repairs - Fee</span>
                        </h3></div>
                    </div>


                </div>




                <div className="c-calculation-currency c-field  c-col-1 c-sml-col-1 c-span-12 c-sml-span-12  c-readonly-always">
                    <div className="c-label  "><label id="c-26-381-label" htmlFor="c-26-381">ARV From
                        Previous Page <span className="c-offscreen c-Required-screenreaders"></span></label>
                    </div>
                    <div className="c-editor">
                        <div className="c-content">${commas(Number(props.ARV))}</div>
                    </div>
                </div>


                <div className= "sm:col-span-2">
                    <div className="c-label  "><label id="c-27-380-label" htmlFor="c-27-380">Discount (% of
                        ARV) <span className="c-offscreen c-Required-screenreaders"></span>
                        <span

                            className="c-offscreen c-validation-screenreaders">
                            <span></span></span>
                    </label>

                    </div>
                    <div className="c-editor">
                        <div className="c-dropdown c-placeholder-text-styled toggle-on">

                            <select

                            value={props.discountSelectpercent} onChange={discounthandleChange}


                            className="block w-52 mb-2 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                                                                                id="c-27-380">



                            <option value="0"></option>
                            <option value="40">40%</option>
                            <option value="45">45%</option>

                            <option value="50">50%</option>
                            <option value="55">55%</option>
                            <option value="60">60%</option>
                            <option value="65">65%</option>
                            <option value="70">70%</option>
                            <option value="75">75%</option>
                            <option value="80">80%</option>
                            <option value="85">85%</option>
                            <option value="90">90%</option>
                        </select>
                            <div className="c-helptext c-html"><p><em>(Usually 70%)</em></p></div>

                        </div>

                    </div>
                </div>



                {/*Square Footage Required*/}

                <div className="">
                    <div className="c-label  "><label id="c-28-379-label" htmlFor="c-28-379">Square
                        Footage <span className="c-offscreen c-Required-screenreaders"></span>  </label>
                    </div>
                    <div className="c-editor c-editor-number">

                        <input className="block mb-8 w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" type="number" id="c-28-379"
                                                                     placeholder=""
                        value={props.sqfoot}
                               onChange={(e)=>{
                                   props.setsqfoot( parseInt(e.target.value))
                               }}
                        />

                    </div>

                </div>

                {/*Repairs (price per square foot)Required*/}
                <div className="sm:col-span-2">
                    <div className="w-full  ">
                        <label id="c-29-378-label" htmlFor="c-29-378">Repairs Calculator (Price
                        Per Square Foot)<span
    className="c-offscreen c-Required-screenreaders"/> </label>
                    </div>

                     <div className="w-full">
                        <div className="c-dropdown c-placeholder-text-styled toggle-on">
                            <select
                                value={props.estimateselectpercent} onChange={selecthandleChange}
                                className="block w-full mb-8 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                                                                                id="c-29-378">
                            <option value="00"></option>
                            <option value="0">$0 - No Repairs - $0.00</option>
                            <option value="5">$5/SF - Seller Says No Repairs -
                                $5.00
                            </option>
                            <option value="10">$10/SF - Cosmetic Repairs 1 -
                                $10.00
                            </option>
                            <option value="15">$15/SF - Cosmetic Repairs 2 -
                                $15.00
                            </option>
                            <option value="20">$20/SF - Mid-Level Rehab 1 - $20.00
                            </option>
                            <option value="25">$25/SF - Mid-Level Rehab 2 - $25.00
                            </option>
                            <option value="30">$30/SF - Full-Gut Rehab 1 - $30.00
                            </option>
                            <option value="35">$35/SF - Full-Gut Rehab 2 - $35.00
                            </option>
                        </select></div>
                    </div>
                </div>




                {/*Total Estimated*/}
                <div className="c-currency c-field  c-col-1 c-sml-col-1 c-span-12 c-sml-span-12">
                    <div className="c-label  "><label id="c-30-377-label" htmlFor="c-30-377">Enter Your Total Estimated Repair Costs
                         <span className="c-offscreen c-Required-screenreaders"/></label>
                    </div>
                    {/*mb-8 block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md*/}

                    <div className="">


                        <MoneyInputComas error={false} value={props.totalestimate.toString()} onChange={totalhandleChangeNew} onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                    </div>
                </div>

                {/*Wholesale Fee*/}
                <div className="c-currency c-field  c-col-13 c-sml-col-1 c-span-12 c-sml-span-12">
                    <div className="c-label  ">
                        <label id="c-31-376-label" htmlFor="c-31-376">Wholesale Fee <span className="c-offscreen c-Required-screenreaders">(Optional)</span> </label>
                    </div>
                    <div className="c-editor c-editor-currency">
                        <MoneyInputComas value={props.fees.toString()} onChange={feehandleChangeNew} onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>


                    </div>
                </div>



                <div className=" sm:col-span-2  ">


                    <div
                        className="c-currency c-field  c-readonly-always c-col-1 c-sml-col-1 c-span-24 c-sml-span-12">
                        <div className="font-semibold ">Method 1: Cash Offer </div>
<div  id="methodcashone">
                        {(() => {
                            let newestimate=props.totalestimate;
                            if(!props.totalestimate||props.totalestimate<0)
                                newestimate=0


                            let newresult = Number(props.ARV) * (props.discountSelectpercent / 100);

                            newresult = newresult - newestimate;

                            newresult = newresult - props.fees;
                            console.log(newresult);
                            return  (result<0 ? <div className="text-red-600 font-semibold">  -${-commas(newresult)} </div> : <div className="c-content">${commas(newresult)}</div>)
                        })()}



</div>



                    </div>





                </div>
                <hr className="sm:col-span-3  w-full"/>
                <div className="sm:col-span-2 text-lg  font-bold mb-3">
                    <div className="text-lg  font-bold"><h3><span
                        style={{textDecoration: ""}} className="text-blue-600">Method 2:&nbsp;Average Lowest Solds x %</span>
                    </h3>
                    </div>
                </div>
                <div className="sm:col-span-2">
                    Lowest Comparable Houses Sold
                </div>


                <table className="table p-4 bg-white shadow rounded-lg col-span-3 ">
                    <thead>
                    <tr>
                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">
                            Delete
                        </th>
                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">
                            Address
                        </th>
                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">
                            Sold Price

                        </th>

                    </tr>
                    </thead>
                    <tbody>


                    {
                      props.stepFourCalcState.source_value.map((source,index)=>(           <tr className="text-gray-700">


                            <td className="border p-4 dark:border-dark-5 text-center">




                                <svg  xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                      onClick={()=>{
                                          props.setStepFourCalcState(
                                              {
                                                  source_value: props.stepFourCalcState.source_value.filter((es, i) => {
                                                      return index !== i
                                                  }),
                                              }


                                          ) ;

                                      }}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>


                            </td>


                            <td className="border p-4 dark:border-dark-5">

                                <input
                                    type="text"
                                    name="last-name"

                                    value={ source.name}
                                    onChange={(e)=>{
                                        // e.target.value
                                        props.setStepFourCalcState({
                                            source_value:props.stepFourCalcState.source_value.map((elem,i)=>{
                                                if( i===index)  elem.name=e.target.value
                                                return elem;
                                            })


                                        })

                                    }}

                                    autoComplete="family-name"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                            </td>



                            <td className="border p-4 dark:border-dark-5">
                                <MoneyInputComas value={source.value}
                                                 onChange={(e)=>{
                                                     // e.target.value
                                                     props.setStepFourCalcState({
                                                         source_value:props.stepFourCalcState.source_value.map((elem,i)=>{
                                                             if( i===index)  elem.value=e
                                                             return elem;
                                                         })


                                                     })

                                                 }}
                                                 onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>


                            </td>

                        </tr>) )

                    }


                    </tbody>
                </table>
                <div className=" col-span-3 text-right          ">
                    Average =
                    ${function (){
                        let avrage=0;
                        let numberofavarge=0;
                    props.stepFourCalcState.source_value.forEach((el)=>{

                         if   (el.value) avrage=avrage+Number(el.value);
                         if   (el.value && Number(el.value)>=0) numberofavarge=numberofavarge+1;

                        })
                        return commas(avrage/numberofavarge) ;
                    }()}

                </div>

                <button
                    onClick={()=>{
                        props.setStepFourCalcState({
                            ...props.stepFourCalcState,source_value:[...props.stepFourCalcState.source_value,{value:"",name:""}]
                        })
                    }}
                    type="button"
                    className=" sm:col-span-3 py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Add An Additional Property
                </button>
                <div className="sm:col-span-3">
                    <hr className="m-6"/>

                </div>

                <div className="mt-4">
                    {/*discountSelect,setdiscountSelect*/}
                    <div className="c-label  "><label id="c-35-366-label" htmlFor="c-35-366">Discount (% of
                        Average) <span className="c-offscreen c-Required-screenreaders"></span></label>
                    </div>
                    <div className="c-editor">
                        <div className="c-dropdown c-placeholder-text-styled toggle-on"><select

                            value={props.discountSelect} onChange={seconddiscounthandleChange}


                            className="block w-52  text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                            id="c-35-366">
                            <option value="0"></option>
                            <option value="70">70%</option>
                            <option value="75">75%</option>
                            <option value="80">80%</option>
                            <option value="85">85%</option>
                            <option value="90">90%</option>
                        </select></div>
                    </div>
                    <div className="c-validation" aria-hidden="true"/>
                    <div className="c-helptext c-html"><p><em>(Usually 80%)</em></p></div>
                </div>
                <div className="mt-4">
                     <div className="">Method 2: Cash Offer</div>
                     <div className="" id="methodcashtwo">${function (){
                         let avrage=0;
                         let numberofavarge=0;
                         props.stepFourCalcState.source_value.forEach((el)=>{

                             if   (el.value) avrage=avrage+Number(el.value);
                             if   (el.value && Number(el.value)>=0) numberofavarge=numberofavarge+1;

                         })
                         console.log("new test");

                         return commas((avrage/numberofavarge)/100*props.discountSelect) ;
                     }()}</div>

                </div>







                <hr className="sm:col-span-3 "/>
                <div className="text-lg  font-bold  sm:col-span-3  ">
                    <div className="c-editor c-html"><h3><span style={{textDecoration: ""}} className="text-blue-600">Calculated Results</span>
                    </h3></div>
                </div>



                <table className="table p-4 bg-white shadow rounded-lg col-span-3">
                    <thead>
                    <tr>

                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">

                            <div className="c-currency c-field  c-readonly-always c-col-1 c-sml-col-1 c-span-11 c-sml-span-12 sm:col-span-2 -mt-3  ">
                                <div className=" text-green-600 font-semibold">Method 1: Cash Offer <span
                                    className="c-offscreen c-Required-screenreaders"></span></div>
                                <div className="c-editor">
                                    <div className="c-content">{(() => {
                                        let newestimate=totalestimate;
                                        if(!totalestimate||totalestimate<0)
                                            newestimate=0


                                        let newresult = Number(props.ARV) * (props.discountSelectpercent / 100);

                                        newresult = newresult - newestimate;

                                        newresult = newresult - props.fees;
                                        return  (result<0 ? <div className="text-red-600 font-semibold"> - ${-commas(newresult)} Losing Money</div> : <div className="">${commas(newresult)}</div>)
                                    })()}

                                    </div>
                                </div>
                                <div className="c-validation" aria-hidden="true"/>
                            </div>

                        </th>
                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">

                            <div className="c-currency c-field  c-readonly-always c-col-12 c-sml-col-1 c-span-12 c-sml-span-12 -mt-3 ">
                                <div className="text-green-600 font-semibold ">Method 2: Cash Offer <span
                                    className="c-offscreen c-Required-screenreaders"></span> </div>
                                <div className="c-editor">
                                    <div className="c-content">${function (){
                                        let avrage=0;
                                        let numberofavarge=0;
                                        props.stepFourCalcState.source_value.forEach((el)=>{

                                            if   (el.value) avrage=avrage+Number(el.value);
                                            if   (el.value && Number(el.value)>=0) numberofavarge=numberofavarge+1;

                                        })
                                        return commas((avrage/numberofavarge)/100*props.discountSelect) ;
                                    }()}</div>
                                </div>
                                <div className="c-validation" aria-hidden="true"/>
                            </div>


                        </th>

                    </tr>
                    </thead>
                </table>



                <div className="sm:col-span-2 mt-8">
                    <div className="text-lg  font-bold  sm:col-span-3 ">
                        <div className="c-editor c-html"><h3><span style={{textDecoration: ""}} className="text-blue-600">Your Offer</span>
                        </h3></div>
                    </div>
                </div>




                <table className="table p-4 bg-white shadow rounded-lg col-span-3">
                    <thead>
                    <tr>

                     <th >

                         <MoneyInputComas error={priceerror} value={props.stepFourState.purchase_price} onChange={(e)=>{

                             // e.target.value
                             props.setStepFourState({
                                 ...props.stepFourState,
                                 purchase_price:e



                             })

                         }} onBlur={(e)=>{
                             setpriceerror(false)
                             if(e==="")
                                 setpriceerror(true)

                         }} label={""} description={""} classes={""} required={"Required"}>
                             Purchase Price - Enter The Amount of Your Cash Offer
                         </MoneyInputComas>

                     </th>
                        <th className="w-8">

                        </th>
<th>

    <MoneyInputComas value={props.stepFourState.earnest}  onChange={(e)=>{

        // e.target.value
        props.setStepFourState({
            ...props.stepFourState,
            earnest:e



        })

    }} onBlur={(e)=>{
        setearnesterror(false)
        if(!e)
            setearnesterror(true)

    }} label={""} description={""} classes={""} required={"Required"}> Earnest Money </MoneyInputComas>


</th>
                    </tr>
                    </thead>
                </table>



                <div className="sm:col-span-2 -mt-3 ">




                    <div className="c-label  mt-6  flex justify-between">
                        <label  className="">Offer Deadline
                        </label>
                        <span className=" text-sm text-gray-500 font-bold">Required</span>

                    </div>




                    <div className="c-editor">
                        <div className="c-editor-date">

                            <input

                                value={props.stepFourState.datestate}

                                onChange={(e)=>{
                                    props.setStepFourState({
                                        ...props.stepFourState,
                                        datestate:e.target.value,
                                        date:{
                                            month:new Date(e.target.value).getMonth().toString(),
                                            day:new Date(e.target.value).getDate().toString(),
                                            year:new Date(e.target.value).getFullYear().toString(),
                                        }
                                    })
                                    console.log(new Date(e.target.value).getDate());
                                }}
                                type="date"
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"

                            />


                        </div>

                        <div className="c-editor-date-icon"><i className="icon-calendar"/></div>
                    </div>



                </div>




            </form>

        </div>
    );
};