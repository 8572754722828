import * as React from 'react';
import {Input} from "./Form/Input";
import {useEffect} from "react";
import {MoneyInputComas} from "./Form/MoneyInputComas";

interface Props {
    ARV: string,
    interestDownPayment:number,
    setInterestDownPayment:Function

    offerprice:number,
    setofferprice:Function,

    principalsellerwant:number,
    setprincipalsellerwant:Function

    principalDiscount:number,
    setprincipalDiscount:Function

    principalPercentPayment:number,
    setPrincipalPercentPayment:Function


    principalPayment:number,
    setPrincipalPayment:Function

    principalRent:number,
    setPrincipalRent:Function


    principalRentOnlyPaymentPercent:number,
    setPrincipalRentOnlyPaymentPercent:Function


    principalRentOnlyPayment:number,
    setPrincipalRentOnlyPayment:Function


    interestSellerWant:number,
    setInterestSellerWant:Function


    interestDiscount:number,
    setInterestDiscount:Function

    interestDownPaymentPercent:number,
    setInterestDownPaymentPercent:Function

    interest:number,
    setinterest:Function

    balanceowed:number,
    setbalanceowed:Function
};

export function StepSix(props: Props) {





    const [principalMonths, setPrincipalMonths] = React.useState(0);
    const [principalYears, setPrincipalYears] = React.useState(0);




    const [Balloon, setBalloon] = React.useState(0);

    useEffect(() => {


        if (props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount) < 0)
            props.setofferprice(0)
        else
            props.setofferprice(props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount))


    }, [props.principalsellerwant, props.principalDiscount])
    useEffect(() => {

        props.setPrincipalPayment((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment)


    }, [props.principalsellerwant, props.principalDiscount, props.offerprice, props.principalPercentPayment])
    useEffect(() => {

        if (((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) - ((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment))
            < 0)
            props.setbalanceowed(0);
        else
            props.setbalanceowed((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) - ((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment))


    }, [props.principalsellerwant, props.principalDiscount, props.offerprice, props.principalPercentPayment,props.principalPayment])

    useEffect(() => {

        if (((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) - ((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment))
            < 0)
            props.setbalanceowed(0);
        else
            props.setbalanceowed((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) - ((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment))


    }, [])


    useEffect(()=>{
        props.setPrincipalRentOnlyPayment(props.principalRent / 100 * props.principalRentOnlyPaymentPercent)
    },[props.principalRentOnlyPaymentPercent, props.principalRent])
    function commas(number: number) {
        console.log(number);
        if(number===0)
            return '';
        let n = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        if (n.slice(-2).charAt(0) === ".")
            n = n + '0';
        return n;
    }

    return (
        <div className="flex w-full   space-x-3">
            <div className="flex  flex-col w-full lg:w-3/4 xl:w-3/6   md:m-auto  pl-4 pr-4 ">


                <div className="sm:col-span-3 mt-12 mb-2">

                    <h3 className="text-3xl"> Owner Financing Offer</h3> <br/>
                    <div className="c-field c-col-1 c-sml-col-1 c-span-24 c-sml-span-12">
                        <div className="text-lg  font-bold text-blue-600 "><h3><span style={{textDecoration: ""}}>Principal Only Payments</span>
                        </h3></div>
                    </div>


                </div>
                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Price the Seller Wants (Or the 'As-Is' Value)


                            </label>

                        </div>


                        <div className="mt-1">



                            <MoneyInputComas value={props.principalsellerwant.toString()}
                                             onChange={(e) => {
                                                 props.setprincipalsellerwant(e)
                                             }}
                                             onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                        </div>
                        <div className=" text-xs">
                            Prior As-Is Calculation: ${props.ARV}


                        </div>


                    </div>

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Discount

                            <select id="animals"
                                    value={props.principalDiscount}
                                    onChange={(e) => props.setprincipalDiscount(parseInt(e.target.value))}
                                    className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                    name="animals">
                                <option value={0}>
                                    0%
                                </option>
                                <option value={5}>
                                    5%
                                </option>
                                <option value={10}>
                                    10%
                                </option>
                                <option value={15}>
                                    15%
                                </option>
                                <option value={20}>
                                    20%
                                </option>

                            </select>
                        </label>

                    </div>

                    <div className="mb-4">
                        <div className="font-semibold">Offer Price</div>
                        {/*<div className="">${function (){*/}
                        {/*    if(principalsellerwant - (principalsellerwant/100 *principalDiscount)<0)*/}
                        {/*        return 0*/}
                        {/*    return commas(principalsellerwant - (principalsellerwant/100 *principalDiscount))*/}
                        {/*}()}</div>*/}

                        <input
                            value={commas(props.offerprice)}
                            onChange={(e) => props.setofferprice(parseInt(e.target.value.replace(/,/g, ''), 10))}
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />

                    </div>

                </div>

                <div className=" mt-1 flex flex-col md:flex-row justify-between ">


                    <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                        <div className="mb-4">
                            <label className="text-gray-700" htmlFor="animals">
                                Down Payment (%)


                                <select
                                    value={props.principalPercentPayment}
                                    onChange={(e) => {
                                        props.setPrincipalPercentPayment(parseInt(e.target.value))
                                    }}
                                    className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                    id="c-75-792">
                                    <option value={0}>%</option>
                                    <option value={5}>5%</option>
                                    <option value={10}>10%</option>
                                    <option value={15}>15%</option>
                                    <option value={20}>20%</option>
                                    <option value={25}>25%</option>
                                    <option value={30}>30%</option>
                                    <option value={35}>35%</option>
                                    <option value={40}>40%</option>
                                    <option value={45}>45%</option>
                                    <option value={50}>50%</option>
                                </select>
                            </label>
                        </div>
                    </div>


                    <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                        <div className="mb-4">


                            <label className="text-gray-700" htmlFor="animals">
                                Down Payment ($)


                                <input
                                    value={commas(props.principalPayment)}
                                    onChange={(e) => props.setPrincipalPayment(parseInt(e.target.value.replace(/,/g, ''), 10))}


                                    type="text"
                                    name="last-name"

                                    id="last-name"
                                    autoComplete="family-name"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                            </label>
                        </div>

                    </div>


                    <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                        <div className="mb-4">
                            <label className="text-gray-700" htmlFor="animals">
                                Balance Owed


                                <div
                                    className="block  "
                                    id="c-75-792">

                                    ${commas(props.balanceowed)}
                                </div>


                            </label>
                        </div>

                    </div>


                </div>
                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Market Rent



                            <MoneyInputComas value={props.principalRent.toString()}
                                             onChange={(e) => props.setPrincipalRent(parseInt(e.replace(/,/g, ''), 10))}
                                             onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                        </label>

                    </div>

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Principal Only Payment

                            <select
                                value={props.principalRentOnlyPaymentPercent}
                                onChange={(e) => {
                                    props.setPrincipalRentOnlyPaymentPercent(parseInt(e.target.value))

                                }

                                }
                                className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                id="c-75-792">
                                <option value={0}>%</option>
                                <option value={30}>30%</option>
                                <option value={35}>35%</option>
                                <option value={40}>40%</option>
                                <option value={45}>45%</option>
                                <option value={50}>50%</option>
                                <option value={55}>55%</option>
                                <option value={60}>60%</option>
                                <option value={65}>65%</option>
                                <option value={70}>70%</option>
                            </select>
                        </label>

                    </div>

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Principal Only Payments ($)

                            <input id="animals"
                                   value={props.principalRentOnlyPayment}
                                   onChange={(e) => props.setPrincipalRentOnlyPayment(parseInt(e.target.value))}
                                   type="text"
                                   className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                   name="animals">

                            </input>
                        </label>

                    </div>


                </div>


                <div className=" mt-4 flex flex-col md:flex-row space-x-24  ">
                    <div className="mb-4">

                        <div className="font-semibold">Months Until Paid
                        </div>
                        <div className="">{function () {
                            if (props.principalRentOnlyPaymentPercent === 0)
                                return 0

                            return Math.round(((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) - ((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment)) / props.principalRentOnlyPayment)

                        }()}</div>

                    </div>
                    <div className="mb-4">
                        <div className="font-semibold">Years</div>

                        <div className="">{
                            function () {
                                if (props.principalRentOnlyPaymentPercent === 0)
                                    return 0

                                return (Math.round(((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) - ((props.principalsellerwant - (props.principalsellerwant / 100 * props.principalDiscount)) / 100 * props.principalPercentPayment)) / props.principalRentOnlyPayment) / 12).toFixed(1)
                            }()}
                        </div>


                    </div>

                </div>

                <hr className="mt-12   mb-12     border-gray-400 "/>


                <h3 className="text-lg  font-bold text-blue-600 "> Interest Only Payments
                </h3>  <br/>


                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Price Seller Wants (Or "As-Is" Value)


                            </label>

                        </div>


                        <div className="mt-1">

                            <MoneyInputComas value={props.interestSellerWant.toString()}
                                             onChange=
                                                 {(e) => props.setInterestSellerWant(parseInt(e.replace(/,/g, ''), 10))}


                                             onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                        </div>
                        <div className=" text-xs">
                            "As-Is" from before: ${props.ARV}


                        </div>


                    </div>

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Discount
                            <select id="animals"
                                    value={props.interestDiscount}
                                    onChange={(e) => {
                                        props.setInterestDiscount(parseInt(e.target.value))
                                    }}
                                    className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                    name="animals">
                                <option value={0}>
                                    0%
                                </option>
                                <option value={5}>
                                    5%
                                </option>
                                <option value={10}>
                                    10%
                                </option>
                                <option value={15}>
                                    15%
                                </option>
                                <option value={20}>
                                    20%
                                </option>

                            </select>
                        </label>

                    </div>

                    <div className="mb-4">
                        <div className="font-semibold">Offer Price</div>
                        <div

                            className="">${commas(props.interestSellerWant - (props.interestSellerWant / 100 * props.interestDiscount))||0}</div>

                    </div>

                </div>


                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Down Payment (%)


                            <select
                                value={props.interestDownPaymentPercent}
                                onChange={(e) => {

                                    props.setInterestDownPaymentPercent(parseInt(e.target.value))
                                    props.setInterestDownPayment(((props.interestSellerWant - (props.interestSellerWant / 100 * props.interestDiscount)) / 100) * parseInt(e.target.value))
                                }}
                                className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                id="c-75-792">
                                <option value={0}>%</option>
                                <option value={5}>5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                                <option value={20}>20%</option>
                                <option value={25}>25%</option>
                                <option value={30}>30%</option>
                                <option value={35}>35%</option>
                                <option value={40}>40%</option>
                                <option value={45}>45%</option>
                                <option value={50}>50%</option>
                            </select>
                        </label>

                    </div>


                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Down Payment ($)





                            <MoneyInputComas value={props.interestDownPayment.toString()}
                                             onChange=
                                                 {(e) => props.setInterestDownPayment(e)}


                                             onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                        </label>

                    </div>

                    <div className="mb-4">
                        <div className="font-semibold">Balance Owed
                        </div>
                        <div className="" id="balanceowned">${
                            function () {
                                if ((((props.interestSellerWant - (props.interestSellerWant / 100) * props.interestDiscount)) - ((props.interestSellerWant - (props.interestSellerWant / 100 * props.interestDiscount)) / 100 * props.interestDownPaymentPercent)
                                ) < 0)
                                    return 0
                                return ((props.interestSellerWant - (props.interestSellerWant / 100) * props.interestDiscount)) - ((props.interestSellerWant - (props.interestSellerWant / 100 * props.interestDiscount)) / 100 * props.interestDownPaymentPercent)

                            }()}

                        </div>
                    </div>

                </div>


                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Interest %


                            <select
                                value={props.interest}
                                onChange={(e) => {
                                    props.setinterest(parseInt(e.target.value))
                                }}
                                className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                id="c-75-792">
                                <option value={0}>0%</option>
                                <option value={1}>1%</option>
                                <option value={2}>2%</option>
                                <option value={3}>3%</option>
                                <option value={4}>4%</option>
                                <option value={5}>5%</option>
                                <option value={6}>6%</option>
                                <option value={7}>7%</option>
                                <option value={8}>8%</option>
                                <option value={9}>9%</option>
                                <option value={10}>10%</option>
                            </select>
                        </label>

                    </div>


                    <div className="mb-4">
                        <div className="font-semibold">Interest Only Payments ($)

                        </div>
                        <div className="">${function () {
                            if (props.interest === 0) return 0
                            if ((((props.interestSellerWant - (props.interestSellerWant / 100) * props.interestDiscount)) - ((props.interestSellerWant - (props.interestSellerWant / 100 * props.interestDiscount)) / 100 * props.interestDownPaymentPercent)
                            ) < 0)
                                return 0

                            return (((((props.interestSellerWant - (props.interestSellerWant / 100) * props.interestDiscount)) - ((props.interestSellerWant - (props.interestSellerWant / 100 * props.interestDiscount)) / 100 * props.interestDownPaymentPercent)) / 12) * (props.interest / 100)).toFixed(2)


                        }()} </div>

                    </div>

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Balloon (Years)


                            <input

                                type="text"
                                name="last-name"

                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </label>

                    </div>


                </div>


            </div>
        </div>
    );
};