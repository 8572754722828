import * as React from 'react';
import {useState} from "react";

interface Props {
    value:string,
    onChange: (value:string) => void,
    onBlur: (value:string) => void,
    label:string
    description:string
    children:any
    classes:string
    required:string,
    error?:boolean
};

export function MoneyInputComasImprovment(props: Props) {
    const [dot, setdot]= useState<string>("");

    function commas(number: string) {


        if(number==="0")
            return '';

        let split=number.split(".");
        if(number.slice(-2)===".."){
            return  number.substring(0, number.length - 1).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }
        if(split[1])
        if(split[1].length===3)
            return  number.substring(0, number.length - 1).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");




        let n = number.toString().replace(/,/g, '');

        n = n.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        //
        // if(split[1])
        //     n=n+"."+split[1];

        // if (n.slice(-2).charAt(0) === ".")
        //     n = n + '0';

        return n;
    }

    return (
        <div className={props.classes}>
            <div className="flex justify-between">

                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    {props.children}

                </label>

                <span id="phone-description" className="text-sm text-gray-500 ">
                    {props.required}
                  </span>

            </div>


            <div className="mt-1">



                <div>

                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">
                $
            </span>
                        </div>
                        <input


                            onBlur={(e) => props.onBlur(e.target.value)}

                            value={commas(props.value.toString())}
                            onChange={(e) => {
                              if(
                                  e.target.value.slice(-1)==="" ||
                                  e.target.value.slice(-1)==="." ||
                                  !isNaN(parseInt(e.target.value.slice(-1)))

                              )props.onChange(e.target.value.replace(/,/g, ''))


                            }}


                            type="text"


                            name="price" id="price"
                            className={props.error?" border-l border-b border-t border-red-500 py-2 px-4  block w-full pl-7 pr-12 sm:text-sm rounded-md":

                            "focus:ring-indigo-500 border-l border-b border-t border-gray-300 py-2 px-4 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm rounded-md"}
                            placeholder="0.00"/>

                        <div className="absolute inset-y-0 right-0 flex items-center">
                            <label htmlFor="currency" className="sr-only">
                                Currency
                            </label>
                            <select id="Currency" name="currency"
                                    className={props.error?" py-2 px-4 border-t border-r border-red-500 border-b bo  h-full pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-r-md":
                                        "focus:ring-indigo-500 py-2 px-4 border-t border-r border-gray-300 border-b bo focus:border-indigo-500 h-full pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-r-md"
                                    }

                            >


                                <option>
                                    USD
                                </option>
                                <option>
                                    CAD
                                </option>
                                <option>
                                    EUR
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
                <div className="pl-2  text-gray-700">{props.description}</div>

            </div>
        </div>
    );
};