import * as React from 'react';
import {useState} from "react";
import {MoneyInput} from "./Form/MoneyInput";
import {MoneyInputComas} from "./Form/MoneyInputComas";

interface stepThreeContract{

    source_value:{name:string,value:string}[],
    ARV:string

}
interface stepThreesecContract{
    source_value:{name:string,value:string}[],
}

interface Props {
    nextError:boolean,

    stepThreeState:stepThreeContract,
    setStepThreeState:(data:{

        source_value: { name: string, value: string }[],
        ARV: string

    })=> void
    changeArv:(data:string)=> void
};



export function StepThree(props: Props) {
    const [arv,setArv]=useState<string>("");

    const languages = [
        {
            name: 'C',
            year: 1972
        },
        {
            name: 'Elm',
            year: 2012
        },
    ];


    // Teach Autosuggest how to calculate suggestions for any given input value. 92883598
    const getSuggestions = (value:any) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : languages.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    const initialStepThreeState:stepThreesecContract={
        source_value:[
            {name:"",value:"0"},
        ],

    };
    const getSuggestionValue = (suggestion:any) => suggestion.name;


// Use your imagination to render suggestions.
    const renderSuggestion = (suggestion:any) => (
        <div>
            {suggestion.name}
        </div>
    );
    const [number,setnumber]=useState([{}]);

    const [stepThreeState,setStepThreeState] =useState<stepThreesecContract>(initialStepThreeState);
    function commased(number: string) {
        console.log(number);
        if(number==="0")
            return '';
        let n = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        // if (n.slice(-2).charAt(0) === ".")
        //     n = n + '0';


            return n;
    }
    function commas(number: number) {
        console.log(number);
        if(number===0)
            return '0';
        if(number===0)
            return '';
        let n = number.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        if (n.slice(-2).charAt(0) === ".")
            n = n + '0';
        return n;
    }

    function tablechangenumber(data:string,index:number){
        props.setStepThreeState({
            source_value:props.stepThreeState.source_value.map((elem,i)=>{
                if( i===index)  elem.value=data
                return elem;
            })
            ,
            ARV:props.stepThreeState.ARV


        })
    }
    return (

        <div className="flex w-full   space-x-3">

            <form action="#" method="POST" className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 w-full lg:w-3/4 xl:w-3/6   md:m-auto  pl-4 pr-4  ">
                <div className="sm:col-span-3 mt-4 mb-2" >
                    <div className="block">

                        <h3 className="text-3xl"> Estimate After Repair Value - ARV</h3> <br/>
                        Enter properties below to get an estimate or simply enter the ARV you want to use below.





                    </div>

                </div>


                <table className="table p-4 bg-white shadow rounded-lg col-span-3">
                    <thead>
                    <tr>
                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">
                            Delete
                        </th>

                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900 ">
                         Source <div className="text-sm">(Zillow, Propstream, Realty Trac, etc.)</div>
                        </th>
                        <th className="border p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">
                            Estimated Value
                        </th>

                    </tr>
                    </thead>
                    <tbody>



                    {
                        props.stepThreeState.source_value.map((source,index)=>(           <tr className="text-gray-700">


                            <td className="border p-4 dark:border-dark-5 text-center">




                                <svg  xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                      onClick={()=>{
                                          props.setStepThreeState(
                                              {
                                                  source_value: props.stepThreeState.source_value.filter((es, i) => {
                                                      return index !== i
                                                  }),
                                                  ARV:props.stepThreeState.ARV
                                              }


                                          ) ;

                                      }}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>


                            </td>

                            <td className="border p-4 dark:border-dark-5">
                                <div className="">
                                <input
                                    type="text"
                                    name="last-name"

                                    value={ source.name}
                                    onChange={(e)=>{
                                        // e.target.value
                                        props.setStepThreeState({
                                            source_value:props.stepThreeState.source_value.map((elem,i)=>{
                                                if( i===index)  elem.name=e.target.value
                                                return elem;
                                            }),
                                            ARV:props.stepThreeState.ARV


                                        })

                                    }}

                                    autoComplete="family-name"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md "
                                />
                                </div>
                            </td>
                            <td className="border p-4 dark:border-dark-5">

                            <MoneyInputComas value={source.value}
                                             onChange={(data)=>{tablechangenumber(data,index)}}
                                             onBlur={()=>{}} label={""} description={""} classes={"block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"} required={""}> </MoneyInputComas>
                            </td>


                        </tr>) )

                    }


                    </tbody>
                </table>
                <div className="sm:col-span-3  text-right font-semibold ">

                    Average =
                    ${function (){
                    let avrage=0;
                    let numberofavarge=0;
                    props.stepThreeState.source_value.forEach((el)=>{

                        if   (el.value) avrage=avrage+Number(el.value);
                        if   (el.value && Number(el.value)>=0) numberofavarge=numberofavarge+1;

                    })
                   if( numberofavarge===0) return 0

                    return commas((avrage/numberofavarge)) ;
                }()}
                </div>



                <button
                    onClick={()=>{
                        props.setStepThreeState({
                            ...props.stepThreeState,source_value:[...props.stepThreeState.source_value,{value:"",name:""}]
                        })
                    }}
                    type="button"
                    className=" sm:col-span-3 py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Add ARV Source
                </button>


                <div className="sm:col-span-2">
                    <MoneyInputComas value={props.stepThreeState.ARV} onChange={props.changeArv} onBlur={()=>{}} label={"aaa"} description={""} classes={"aa"} required={"Required"}> Enter The ARV You Want To Use</MoneyInputComas>
                    {props.stepThreeState.ARV==="" && props.nextError && <div className="text-red-500">Enter Arv</div>}

                </div>


            </form>

        </div>
    );
};