import * as React from 'react';
import {useState} from "react";
import Vimeo from '@u-wave/react-vimeo';

interface detailsContract{
    companyName:string,
    name:string,
    fax:string,
    BusinessphoneNumber:string,
    date:string,
    address:string,
    email:string,
    website:string,
    city:string,
    state:string,
    postalCode:string,
    buyerWebsite:string,
}
interface Props {
    nextError:boolean
    setdetails:Function
    details:detailsContract
};

export function StepOne(props: Props) {
    const [number,setnumber]=useState([{}]);

    return (

        <div className="flex w-full   space-x-3">

            <form action="#" method="POST" className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 w-full lg:w-3/4 xl:w-3/6   md:m-auto  pl-4 pr-4  ">
                <div className="sm:col-span-3 mt-4 mb-2" >


                    <div className="block">

                        <h3 className="text-3xl"> Buyer Information </h3> <br/>


                    </div>

                </div>
                <div className="sm:col-span-2">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Company Name:

                    </label>
                    <div className="mt-1">
                        <input type="text" id="contact-form-company"

                               value={props.details.companyName}
                               onChange={(e)=>{props.setdetails({...props.details,companyName:e.target.value}) }}

                               className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                               placeholder="company Name"/>
                    </div>
                    {props.details.companyName==="" && props.nextError && <div className="text-red-500">Enter Company name </div>}

                </div>

                <div >
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Your Name
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.details.name}
                            onChange={(e)=>{props.setdetails({...props.details,name:e.target.value}) }}

                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.name==="" && props.nextError && <div className="text-red-500">Enter your name </div>}

                </div>


                <div >
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Company Phone Number:
                    </label>
                    <div className="mt-1">
                        <input


                            value={props.details.BusinessphoneNumber}
                            onChange={(e)=>{props.setdetails({...props.details,BusinessphoneNumber:e.target.value}) }}


                            id="phone"
                            name="phone"
                            type="text"
                            autoComplete="phone"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.BusinessphoneNumber==="" && props.nextError && <div className="text-red-500">Enter Company Phone </div>}

                </div>

                <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Fax
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.details.fax}
                            onChange={(e)=>{props.setdetails({...props.details,fax:e.target.value}) }}



                            type="text"
                            name="fax"
                            id="fax"
                            autoComplete="fax"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                </div>


                <div >
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Date
                    </label>
                    <div className="mt-1">
                        <input
                            value={props.details.date}
                            onChange={(e)=>{props.setdetails({...props.details,date:e.target.value}) }}
                            type="date"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.date==="" && props.nextError && <div className="text-red-500">please select Date </div>}

                </div>





                <div className="sm:col-span-3">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                        Company Address:

                    </label>
                    <div className="mt-1">
                        <input

                            value={props.details.address}
                            onChange={(e)=>{props.setdetails({...props.details,address:e.target.value}) }}

                            placeholder="Address Line "
                            type="text"
                            name="address"
                            id="address"
                            autoComplete="address"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.address==="" && props.nextError && <div className="text-red-500">please fill Company Address </div>}

                </div>

                <div className="">
                    <div className="flex justify-between">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            City

                        </label>

                    </div>
                    <div className="mt-1">
                        <input

                            value={props.details.city}
                            onChange={(e)=>{props.setdetails({...props.details,city:e.target.value}) }}


                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            aria-describedby="phone-description"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.city==="" && props.nextError && <div className="text-red-500">city required </div>}

                </div>
                <div className="">
                    <div className="flex justify-between">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            State
                        </label>

                    </div>
                    <div className="mt-1">
                        <input

                            value={props.details.state}
                            onChange={(e)=>{props.setdetails({...props.details,state:e.target.value}) }}

                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            aria-describedby="phone-description"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.state==="" && props.nextError && <div className="text-red-500">State required </div>}

                </div>
                <div className="">
                    <div className="flex justify-between">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Zip Code
                        </label>
                    </div>
                    <div className="mt-1">
                        <input


                            value={props.details.postalCode}
                            onChange={(e)=>{props.setdetails({...props.details,postalCode:e.target.value}) }}

                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            aria-describedby="phone-description"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.details.postalCode==="" && props.nextError && <div className="text-red-500">Zip Code required </div>}

                </div>



                <div>
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Your Email
                    </label>
                    <div className="mt-1">
                        <input
                            value={props.details.email}
                            onChange={(e)=>{props.setdetails({...props.details,email:e.target.value}) }}

                            type="text" id="contact-form-name"
                               className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                               placeholder="Email"/>
                    </div>

                    {props.details.email==="" && props.nextError && <div className="text-red-500">Enter Your  Email </div>}

                </div>





                <div className="sm:col-span-2">
                    <div className="flex justify-between">

                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Website

                    </label>

                    </div>


                    <div className="mt-1">
                        <input
                            value={props.details.website}
                            onChange={(e)=>{props.setdetails({...props.details,website:e.target.value}) }}
                            type="text"
                            name="website"
                            id="website"
                            autoComplete="website"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>

                </div>

            </form>

        </div>
    );
};