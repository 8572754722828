import {useEffect, useRef, useState} from 'react'
import {Tab} from '@headlessui/react'
import logo from './Logo.png'
import Vimeo from '@u-wave/react-vimeo';


import {StepOne} from "./components/StepOne";
import {StepTwo} from "./components/StepTwo";
import {StepThree} from "./components/StepThree";
import {StepFour} from "./components/StepFour";
import {StepFive} from "./components/StepFive";
import {StepSix} from "./components/StepSix";


import {Interface} from "readline";
import axios from "axios";
import * as React from "react";
import Modal from 'react-modal';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}


console.log(window.location.search);
const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
console.log(urlParams.get('contact_id'));
console.log();
console.log(urlParams.get('email'));


interface detailsContract {

    companyName: string,
    name: string,
    BusinessphoneNumber: string,
    fax: string,
    date: string,
    address: string,
    email: string,
    website: string,
    city: string,
    state: string,
    postalCode: string,
    seller_name: string,
    property_address: string,
    buyerWebsite: string,

    source_value: { name: string, value: string }[]

}

const InitialDetailsState: detailsContract = {
    companyName: "",
    name: "",
    BusinessphoneNumber: "",
    fax: "",
    date: "2021-11-18",
    address: "",
    email: "",
    website: "",
    seller_name: "",
    property_address: "",
    city: "",
    state: "",
    postalCode: "",
    buyerWebsite: "",

    source_value: [
        {name: "zillow", value: "180000"},
        {name: "Realty Trac", value: "195000"}

    ]

};


interface stepThreeContract {

    source_value: { name: string, value: string }[],
    ARV: string

}

interface stepTwoContract {

    sellerName: string,
    propertyAddress: string,
    propertyCity: string,
    propertyState: string,
    propertyZipCode: string,
    sellerAddress: string,
    sellerCity: string,
    sellerState: string,
    sellerZipCode: string,
    sellerEmail: string,

}

const initialStepTwoState: stepTwoContract = {
    propertyAddress: urlParams.get('address1') || "",
    propertyCity: urlParams.get('city') || "",
    propertyState: urlParams.get('state') || "",
    sellerName: urlParams.get('first_name') + " " + urlParams.get('last_name') || "",
    propertyZipCode: urlParams.get('postal_code') || "",
    sellerZipCode: urlParams.get('postal_code') || "",
    sellerAddress: urlParams.get('address') || "",
    sellerCity: urlParams.get('city') || "",
    sellerState: urlParams.get('state') || "",
    sellerEmail: urlParams.get('address1') || "",
}







// const initialStepThreeState:stepThreeContract={
//     source_value:[
//         {name:"Zillow",value:"180000"},
//         {name:"Realty Trac",value:"195000"}],
//     ARV:"200000"
//
// };

interface stepFourContract {
    datestate: string,
    date: { month: string, day: string, year: string },
    earnest: string,
    methodOne: string,
    methodTwo: string
    purchase_price: string
}

const initialStepFourState: stepFourContract = {
    datestate: "",
    date: {month: "", day: "", year: ""},
    earnest: "0",
    methodOne: "",
    methodTwo: "",
    purchase_price: "",

};

interface stepFiveContract {
    source_value: { name: string, value: string }[],

}
interface stepFourCalcContract {
    source_value: { name: string, value: string }[],

}

const initialStepFourCalcState: stepFourCalcContract = {
    source_value: [
        {name: "", value: "0"},
    ]

};

const initialStepFiveState: stepFiveContract = {
    source_value: [
        {name: "", value: "0"},
    ]

};



export default function Example() {
    const [nextError, setNextError] = useState<boolean>(false);
    const initialStepThreeState: stepThreeContract = {
        source_value: [
            {name: "", value: ""},
        ],
        ARV: ""

    };



    const [loadingStepTwo, setLoadingStepTwo] = useState(false);
    const [loadingStepOne, setLoadingStepOne] = useState(false);

    const [checked, setChecked] = useState(false);
    const [errors, seterrors] = useState("");
    const [totalestimate, settotalestimate] = useState(0);

    const [details, setdetails] = useState<detailsContract>(InitialDetailsState);
    const [stepThreeState, setStepThreeState] = useState<stepThreeContract>(initialStepThreeState);
    const [stepFourState, setStepFourState] = useState<stepFourContract>(initialStepFourState);

    const [setStepFiveState, setsetStepFiveState] = useState<stepFiveContract>(initialStepFiveState);
    const [stepFourCalcState, setStepFourCalcState] = useState<stepFourCalcContract>(initialStepFourCalcState);

    const [stepTwoState, setstepTwoState] = useState<stepTwoContract>(initialStepTwoState)

    const [estimateselectpercent, setestimateselectpercent] = useState<number>(0);
    const [discountSelectpercent, setdiscountSelectpercent] = useState<number>(0);
    const [sqfoot, setsqfoot] = useState<number>(0);
    const [discountSelect, setdiscountSelect] = useState(0);
    const [fees, setfees] = useState<number>(0);

    // Step five

    const [equity, setequity] = useState<number>(0);
    const [interestDownPayment, setInterestDownPayment] = React.useState(0);
    const [offerprice, setofferprice] = React.useState(0);

    const [marketrent, setmarketrent] = useState<number>(0);
    const [cashflow, setcashflow] = useState<number>(0);
    const [optiondeposit, setoptiondeposit] = useState<number>(0);


    const [secpriceseller, setsecpriceseller] = useState<number>(0);
    const [secoptiondeposit, setsecoptiondeposit] = useState<number>(0);
    const [yearnumber, setyearnumber] = useState<number>(0);
    const [firstyearnumber, setfirstyearnumber] = useState<number>(0);


    const [wholesalerent, setwholesalerent] = useState<number>(0);

    const [optionpricetoseller, setoptionpricetoseller] = useState<number>(0);


    const [principalsellerwant, setprincipalsellerwant] = React.useState(0);
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);



    // Step Six Data


    const [principalDiscount, setprincipalDiscount] = React.useState(0);

    const [principalPercentPayment, setPrincipalPercentPayment] = React.useState(0);
    const [principalPayment, setPrincipalPayment] = React.useState(0);



    const [principalRent, setPrincipalRent] = React.useState(0);

    const [principalRentOnlyPaymentPercent, setPrincipalRentOnlyPaymentPercent] = React.useState(0);

    const [principalRentOnlyPayment, setPrincipalRentOnlyPayment] = React.useState(0);



    const [interestSellerWant, setInterestSellerWant] = React.useState(0);
    const [interestDiscount, setInterestDiscount] = React.useState(0);


    const [interestDownPaymentPercent, setInterestDownPaymentPercent] = React.useState(0);
    const [interest, setinterest] = React.useState(0);
    const [balanceowed, setbalanceowed] = React.useState<any>(0);
    function commas(number: number) {
        console.log(number);
        if(number===0)
            return '';
        let n = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        if (n.slice(-2).charAt(0) === ".")
            n = n + '0';
        return n;
    }
    async function load() {
        let detaildone=false;
        try{
            let response=await axios.get(`https://test.getgroupleads.com/api/pdfdatabase/load`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'contactid': urlParams.get('contact_id')||'',
                    'locationid': urlParams.get('location_id')||'',
                }
            });

            console.log(response.data.details);
            if(response.data.details){
                setdetails(response.data.details);
                detaildone=true;
            }

            if(response.data.stepTwoState)
            setstepTwoState(response.data.stepTwoState);

            if(response.data.stepFiveState)
            setsetStepFiveState(response.data.stepFiveState);

            if(response.data.stepThreeState)
            setStepThreeState(response.data.stepThreeState);

            if(response.data.stepFour) {
                setdiscountSelect(response.data.stepFour.discountSelect);
                setdiscountSelectpercent(response.data.stepFour.discountSelectpercent);
                setestimateselectpercent(response.data.stepFour.estimateselectpercent);
                response.data.stepFour.stepFourCalcState.source_value && setStepFourCalcState(response.data.stepFour.stepFourCalcState);
                setfees(response.data.stepFour.fees);
                setsqfoot(response.data.stepFour.sqfoot);
                settotalestimate(response.data.stepFour.totalestimate);
                // discountSelect: 0
                // discountSelectpercent: "60"
                // estimateselectpercent: 0
                // fees: 0
                // sqfoot: 0
                // totalestimate
            }



            if(response.data.stepFive) {

                setcashflow(response.data.stepFive.cashflow)
                setequity(response.data.stepFive.equity)

                setfirstyearnumber(response.data.stepFive.firstyearnumber)
                response.data.stepFive.wholesalerent&&  setwholesalerent(response.data.stepFive.wholesalerent)


                setmarketrent(response.data.stepFive.marketrent)
                setoptiondeposit(response.data.stepFive.optiondeposit)
                setoptionpricetoseller(response.data.stepFive.optionpricetoseller)
                setsecoptiondeposit(response.data.stepFive.secoptiondeposit)
                setsecpriceseller(response.data.stepFive.secpriceseller)
                settotalestimate(response.data.stepFive.totalestimate)
                setyearnumber(response.data.stepFive.yearnumber)

                // cashflow: 15
                // equity: 0
                // firstyearnumber: 3
                // marketrent: 0
                // optiondeposit: 0
                // optionpricetoseller: 0
                // secoptiondeposit: 0
                // secpriceseller: 0
                // totalestimate: 0
                // yearnumber: 0

            }

            if(response.data.stepSix) {




                setInterestDownPayment(response.data.stepSix.interestDownPayment)
                setofferprice(response.data.stepSix.offerprice)
                setprincipalsellerwant(response.data.stepSix.principalsellerwant)
                setprincipalDiscount(response.data.stepSix.principalDiscount)
                setPrincipalPercentPayment(response.data.stepSix.principalPercentPayment)

                setPrincipalPayment(response.data.stepSix.principalPayment)
                setPrincipalRent(response.data.stepSix.principalRent)
                setInterestDiscount(response.data.stepSix.principalRent)
                setInterestSellerWant(response.data.stepSix.interestSellerWant)
                setPrincipalRentOnlyPayment(response.data.stepSix.principalRentOnlyPayment)
                setPrincipalRentOnlyPaymentPercent(response.data.stepSix.principalRentOnlyPaymentPercent)
                setbalanceowed(response.data.stepSix.balanceowed)
                setinterest(response.data.stepSix.interest)
                setInterestDownPaymentPercent(response.data.stepSix.interestDownPaymentPercent)


                // cashflow: 15
                // equity: 0
                // firstyearnumber: 3
                // marketrent: 0
                // optiondeposit: 0
                // optionpricetoseller: 0
                // secoptiondeposit: 0
                // secpriceseller: 0
                // totalestimate: 0
                // yearnumber: 0

            }




            if(response.data.stepFourState)
            setStepFourState(response.data.stepFourState);

        }catch(e){
            console.log(e);
        }



        try {
            const config = {
                headers: {
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55X2lkIjoiSEcwMXF5TnNrM3BoaFptVmxlNWwiLCJ2ZXJzaW9uIjoxLCJpYXQiOjE2MzcwMDY3MDI4NDIsInN1YiI6IkVuVEU0bVVOeTI4RDJ4Y011bGdFIn0.g54zaImUBLIy4Hu7-rmguSOIlKwNGuVTvvrBmbgXyeQ",
                    "Content-Type": "application/json"
                }
            };
            const {data} = await axios.get(`https://rest.gohighlevel.com/v1/locations/${urlParams.get('location_id')}`, config)

            const config2 = {
                headers: {
                    "Authorization": `Bearer ${data.apiKey}`,
                    "Content-Type": "application/json"
                }
            };

            const contact = await axios.get(`https://rest.gohighlevel.com/v1/contacts/${urlParams.get('contact_id')}`, config2)
            console.log(contact.data);
            console.log(contact.data);
            console.log(contact.data.contact);

            const response = await axios.get("https://rest.gohighlevel.com/v1/users/location", config2)

            const secresponse = await axios.get("https://rest.gohighlevel.com/v1/custom-values/", config2)


            if(!detaildone){
                const customresponse = await axios.get("https://rest.gohighlevel.com/v1/custom-values/" + secresponse.data.customValues[0].id, config2)
                console.log(customresponse.data);
                console.log(data.website);


                setdetails({
                    address: data.business.address,
                    date: "2021-11-18",
                    property_address: "",
                    seller_name: "",
                    source_value: [],
                    email: response.data.users[0].email,
                    name: response.data.users[0].firstName + " " + response.data.users[0].lastName,
                    fax: "",
                    BusinessphoneNumber: data.phone,
                    companyName: data.business.name,
                    website: data.website || "",
                    buyerWebsite: data.website || "",
                    city: data.business.city,
                    state: data.business.state,

                    postalCode: data.business.postalCode,
                })
            }

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        console.log("first time");
        load()

    }, [])

    async function fetchData() {
        // const response = await fetch(`https://rest.gohighlevel.com/v1/contacts/${urlParams.get('first_name')}`);
        // const data = await response.json();
        // console.log(data);
        // setdetails(data);
    }


    function changeARV(data: string) {
        setStepThreeState({source_value: stepThreeState.source_value, ARV: data})
    }

    function changenewARV(data: string) {
        setStepThreeState({source_value: stepThreeState.source_value, ARV: data})
    }


    let stepone: any = (
        <form className="flex w-full  space-x-3">
            <div className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
                <div className="mb-6 text-3xl font-light text-center text-gray-800 dark:text-white">
                    Contact us !
                </div>
                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                    <div className="col-span-2 lg:col-span-1">
                        <div className=" relative ">
                            <input type="text" id="contact-form-name"
                                   className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                   placeholder="Name"/>
                        </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                        <div className=" relative ">
                            <input type="text" id="contact-form-email"
                                   className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                   placeholder="email"/>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label className="text-gray-700" htmlFor="name">
                            <textarea
                                className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                id="comment" placeholder="Enter your comment" name="comment" rows={5} cols={40}>
                            </textarea>
                        </label>
                    </div>
                    <div className="col-span-2 text-right">
                        <button type="submit"
                                className="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );

    const [selectedTab, setSelectedTab] = useState(0);
    const tabCount = 6;

    useEffect(() => {

        window.scrollTo({
            left: 100,
            behavior: "auto"
        });

    }, []);
    useEffect(() => {

        window.scrollTo({
            left: 100,
            behavior: "auto"
        });

    }, [selectedTab]);


    useEffect(() => {

            window.scrollTo({
                top: 0,
                behavior: "auto"
            });

    }, [selectedTab])   ;


    let [categories] = useState({

        "Property Info": stepone,
        "After Repair Value": stepone,
        "Cash Offer": stepone,
        "Lease Option": stepone,
        "Owner Financing": stepone,
    })

    const tabs = [
        {name: 'Buyer Information', href: '#', current: false},
        {name: 'Property Information', href: '#', current: false},
        {name: 'After Repair Value', href: '#', current: true},
        {name: 'Cash Offer', href: '#', current: false},
        {name: 'Lease Option', href: '#', current: false},
        {name: 'Owner Financing', href: '#', current: false},
    ]

    function classNames(index: number, ...classes: any) {
        if ((index === 4 || index === 5) && false)
            return "border-transparent text-gray-500   w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-not-allowed"

        return classes.filter(Boolean).join(' ')
    }

    const handleChange = () => {
        setChecked(!checked);
    };

   async function save(step:number) {
       try{
           let url='';
           if(step===0)
                url=`https://test.getgroupleads.com/api/pdfdatabase/save`;
           if(step===1)
               url=`https://test.getgroupleads.com/api/pdfdatabase/savesteptwo`
           if(step===2)
               url=`https://test.getgroupleads.com/api/pdfdatabase/stepThreeState`

           if(step===3)
               url=`https://test.getgroupleads.com/api/pdfdatabase/stepFourState`

           if(step===4)
               url=`https://test.getgroupleads.com/api/pdfdatabase/stepFiveState`
           if(step===5)
               url=`https://test.getgroupleads.com/api/pdfdatabase/stepSixState`

           await axios.post(url, {
               contactid: urlParams.get('contact_id'),
               locationid: urlParams.get('location_id'),
               step:step,
               property:details,
               stepTwoState:stepTwoState,
               stepThreeState:stepThreeState,
               stepFourState:stepFourState,
               stepFiveState:setStepFiveState,

               stepFour:{
                   discountSelectpercent:discountSelectpercent,
                   totalestimate:totalestimate,
                   estimateselectpercent:estimateselectpercent,
                   sqfoot:sqfoot,
                   discountSelect:discountSelect,
                   stepFourCalcState:stepFourCalcState,
                   fees:fees,
               },

               stepFive:{

                   totalestimate:totalestimate,
                   cashflow:cashflow,
                   equity:equity,
                   marketrent:marketrent,
                   optiondeposit:optiondeposit,
                   secoptiondeposit:secoptiondeposit,
                   secpriceseller:secpriceseller,
                   yearnumber:yearnumber,
                   optionpricetoseller:optionpricetoseller,
                   firstyearnumber:firstyearnumber,
                   wholesalerent:wholesalerent,


               },
               stepSix:{

                   interestDownPayment:interestDownPayment,
                   offerprice:offerprice,
                   principalsellerwant:principalsellerwant,
                   principalDiscount:principalDiscount,
                   principalPercentPayment:principalPercentPayment,

                   principalPayment:principalPayment,
                   principalRent:principalRent,
                   principalRentOnlyPaymentPercent:principalRentOnlyPaymentPercent,
                   interestDiscount:interestDiscount,
                   interestSellerWant:interestSellerWant,
                   principalRentOnlyPayment:principalRentOnlyPayment,
                   interestDownPaymentPercent:interestDownPaymentPercent,
                   balanceowed:balanceowed,
                   interest:interest,


               },



               test:setStepFiveState,
           });

       }catch (e) {
           console.log(e)
       }

    }

    function NextHandler() {
        setNextError(false);
        if(selectedTab===0)
        if(details.name==="" || details.city===""||
            details.companyName===""||
            details.name===""||

        details.BusinessphoneNumber===""||
        details.date===""||
        details.address===""||
        details.email===""||
        details.city===""||
        details.state===""||
        details.postalCode===""

        )
        {
            setNextError(true);
            return;
        }
        if(selectedTab===1)

        if(
            stepTwoState.sellerName===""||
            stepTwoState.propertyAddress===""||
            stepTwoState.propertyCity===""||
            stepTwoState.propertyState===""||
            stepTwoState.propertyZipCode===""||
            stepTwoState.sellerAddress===""||
            stepTwoState.sellerCity===""||
            stepTwoState.sellerState===""||
            stepTwoState.sellerZipCode===""||
            stepTwoState.sellerEmail===""

        )
        {
            setNextError(true);
            return;
        }


        if(selectedTab===2)

            if(
                stepThreeState.ARV===""

            )
            {
                setNextError(true);
                return;
            }
        save(selectedTab);




        selectedTab < 5 && setSelectedTab((selectedTab + 1) % tabCount);
    }

    const customStyles = {
        content: {
            width: '100%',
            height: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const [VideoModalIsOpen, setVideoModalIsOpen] = React.useState(false);

    function openModal() {
        setVideoModalIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setVideoModalIsOpen(false);
    }
    return (
        <div className="w-full ml-6 mr-6 h-full   px-4  sm:px-2 ">

            <div
                onClick={() => {
                    setVideoModalIsOpen(true);
                }}
                className="text-sm text-blue-500 underline hover:text-blue-700 text-right pt-2 pr-2 cursor-pointer">
               Video Help
            </div>

            <Modal
                isOpen={VideoModalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <iframe
                    src="https://player.vimeo.com/video/668329809?h=36d4c365fb&amp;speed=0&amp;autoplay=1&amp;app_id=122963"
                    width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                     allowFullScreen title="Offer Generator Demo.mp4" data-ready="true"></iframe>

            </Modal>
            <div className="ml-8 flex justify-center  ">
                <img src={logo} className="w-60 ml-8  text-center"/>
            </div>
            <div className="ml-8  text-center">

                {urlParams.get('first_name') + ' ' + urlParams.get('last_name')}
            </div>
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex" aria-label="Tabs">
                            {tabs.map((tab, index) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(index,
                                        index === selectedTab
                                            ? 'border-blue-600 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
                                    )}

                                    aria-current={tab.current ? 'page' : undefined}
                                    onClick={() => {
                                        if ((index === 4 || index === 5) && false)
                                            return
                                        setSelectedTab(index)
                                    }}
                                >
                                    {tab.name}

                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {
                selectedTab === 0 ? (<StepOne details={details} setdetails={setdetails} nextError={nextError}/>) :
                    selectedTab === 1 ? (<StepTwo stepTwoState={stepTwoState} setstepTwoState={setstepTwoState} nextError={nextError}/>) :
                        selectedTab === 2 ? (
                                <StepThree
                                    stepThreeState={stepThreeState}
                                    setStepThreeState={setStepThreeState}
                                    changeArv={changeARV}

                                    nextError={nextError}/>) :
                            selectedTab === 3 ? (
                                    <StepFour ARV={stepThreeState.ARV} updateARV={changeARV}

                                              stepFourState={stepFourState}
                                              setStepFourState={setStepFourState}
                                              settotalestimate={settotalestimate}
                                              totalestimate={totalestimate}

                                              estimateselectpercent={estimateselectpercent}
                                              setestimateselectpercent={setestimateselectpercent}

                                              discountSelectpercent={discountSelectpercent}
                                              setdiscountSelectpercent={setdiscountSelectpercent}

                                              stepFourCalcState={stepFourCalcState}
                                              setStepFourCalcState={setStepFourCalcState}


                                              sqfoot={sqfoot}
                                              setsqfoot={setsqfoot}
                                              discountSelect={discountSelect}
                                              setdiscountSelect={setdiscountSelect}

                                              fees={fees}
                                              setfees={setfees}
                                    />) :
                                selectedTab === 4 ? (<StepFive ARV={stepThreeState.ARV} updateARV={changeARV}
                                                               stepFiveState={setStepFiveState}
                                                               setStepFiveState={setsetStepFiveState}
                                                               stepFourState={stepFourState}
                                                               settotalestimate={settotalestimate}
                                                               totalestimate={totalestimate}
                                                               cashflow={cashflow}
                                                               equity={equity}
                                                               marketrent={marketrent}
                                                               optiondeposit={optiondeposit}
                                                               secoptiondeposit={secoptiondeposit}
                                                               secpriceseller={secpriceseller}
                                                               setcashflow={setcashflow}
                                                               setequity={setequity}
                                                               setmarketrent={setmarketrent}
                                                               setoptiondeposit={setoptiondeposit}
                                                               setsecoptiondeposit={setsecoptiondeposit}
                                                               setsecpriceseller={setsecpriceseller}
                                                               setyearnumber={setyearnumber}
                                                               yearnumber={yearnumber}
                                                               optionpricetoseller={optionpricetoseller}
                                                               setoptionpricetoseller={setoptionpricetoseller}
                                                               setfirstyearnumber={setfirstyearnumber}
                                                               firstyearnumber={firstyearnumber}
                                                               wholesalerent={wholesalerent}
                                                               setwholesalerent={setwholesalerent}


                                    />) :
                                    selectedTab === 5 ? (<StepSix
                                            ARV={stepThreeState.ARV}
                                            interestDownPayment={interestDownPayment}
                                            setInterestDownPayment={setInterestDownPayment}
                                            offerprice={offerprice}
                                            setofferprice={setofferprice}
                                            principalsellerwant={principalsellerwant}
                                            setprincipalsellerwant={setprincipalsellerwant}
                                            principalDiscount={principalDiscount}
                                            setprincipalDiscount={setprincipalDiscount}
                                            principalPercentPayment={principalPercentPayment}
                                            setPrincipalPercentPayment={setPrincipalPercentPayment}

                                            setPrincipalPayment={setPrincipalPayment}
                                            principalPayment={principalPayment}
                                            principalRent={principalRent}
                                            principalRentOnlyPaymentPercent={principalRentOnlyPaymentPercent}
                                            interestDiscount={interestDiscount}
                                            interestSellerWant={interestSellerWant}
                                            setPrincipalRent={setPrincipalRent}
                                            principalRentOnlyPayment={principalRentOnlyPayment}
                                            setInterestDiscount={setInterestDiscount}
                                            setInterestSellerWant={setInterestSellerWant}
                                            setPrincipalRentOnlyPayment={setPrincipalRentOnlyPayment}
                                            setPrincipalRentOnlyPaymentPercent={setPrincipalRentOnlyPaymentPercent}
                                            interestDownPaymentPercent={interestDownPaymentPercent}
                                            balanceowed={balanceowed}
                                            interest={interest}
                                            setbalanceowed={setbalanceowed}
                                            setinterest={setinterest}
                                            setInterestDownPaymentPercent={setInterestDownPaymentPercent}

                                        />)
                                        : ""
            }


            <div className="flex w-full   space-x-3 ">

                <div className="m-auto w-full  sm:gap-x-8 w-3/6 text-right pt-12 p-12">
                    {selectedTab === 5 &&
                    <div className="w-full mb-5 ">
                        <button
                            className="py-2 px-4 mt-10 mr-4 border-2 focus:ring-blue-500 focus:ring-offset-blue-200 border-blue-600 w-20 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                            onClick={() => selectedTab > 0 && setSelectedTab((selectedTab + tabCount - 1) % tabCount)}>Back
                        </button>
                        <button
                            className="py-2 px-4 mt-10 mr-4 border-2 focus:ring-blue-500 focus:ring-offset-blue-200 border-blue-600 w-20 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                            onClick={() => save(5)}>Save
                        </button>
                    </div>}
                    {selectedTab === 5 &&
                    <div className="w-full ">

                        <input type={"checkbox"}
                               checked={checked}
                               onChange={handleChange}
                               className="mr-2"/>
                        I have reviewed and agreed to REI Software's <a href="https://www.reisoftware.com/terms"
                                                                        className="underline" target="_blank"> Terms of
                        Use</a>.
                    </div>}
                    {errors && (<div className="text-red-600">{errors}</div>)}
                    <button
                        className="py-2 px-4 mt-10 mr-4 border-2 focus:ring-blue-500 focus:ring-offset-blue-200 border-blue-600 w-20 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        onClick={() => selectedTab > 0 && setSelectedTab((selectedTab + tabCount - 1) % tabCount)}>Back
                    </button>

                    {selectedTab === 5 ?
                        <>
                            <button
                                className={
                                    checked ? "py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " :
                                        "py-2 px-4  bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg cursor-not-allowed"
                                }
                                onClick={() => {
                                    seterrors("")
                                    if (!checked) {
                                        seterrors("Please agree to the Terms of Use.")


                                        return
                                    }


                                    let methodone = document.getElementById("methodcashtwo")?.innerText;
                                    let methodtwo = document.getElementById("methodcashone")?.innerText;
                                    let balanceowned = document.getElementById("balanceowned")?.innerText||'';
                                    console.log(balanceowned);

                                    fetch('https://test.getgroupleads.com/api/pdfoptions/option3', {
                                            method: "POST",
                                            body: JSON.stringify({
                                                contactid: urlParams.get('contact_id'),
                                                locationid: urlParams.get('location_id'),
                                                details: details,
                                                stepfour: stepFourState,
                                                steptwo: stepTwoState,
                                                methodone: methodone,
                                                methodtwo: methodtwo,
                                                down_payment_option_2: interestDownPayment,
                                                purchase_price: commas(parseInt(stepFourState.purchase_price)),
                                                balanceowned: commas(parseInt(balanceowned.replace('$',''))),
                                                purchase_price_option_2: commas(optionpricetoseller),
                                            }),
                                            headers: {
                                                "Content-Type": "application/json; charset=utf-8"
                                            }
                                        }
                                    ).then(response => response.blob())
                                        .then(blob => {
                                            var url = window.URL.createObjectURL(blob);
                                            var a = document.createElement('a');
                                            a.href = url;
                                            a.download = `offer.docx`;
                                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                                            a.click();
                                            a.remove();  //afterwards we remove the element again
                                        });


                                }
                                }>
                                 Combined Options DOC
                            </button>

                            <button
                                className={
                                    checked ? "py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ml-6" :
                                        "py-2 px-4  bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg cursor-not-allowed ml-6"
                                }
                                onClick={() => {
                                    seterrors("")
                                    if (!checked) {
                                        seterrors("Please agree to the Terms of Use.")


                                        return
                                    }


                                    let methodone = document.getElementById("methodcashtwo")?.innerText;
                                    let methodtwo = document.getElementById("methodcashone")?.innerText;

                                    fetch('https://test.getgroupleads.com/api/pdf/terter', {
                                            method: "POST",
                                            body: JSON.stringify({
                                                contactid: urlParams.get('contact_id'),
                                                email: urlParams.get('email'),
                                                phone: urlParams.get('phone'),
                                                full_name: urlParams.get('full_name'),
                                                first_name: urlParams.get('first_name'),
                                                last_name: urlParams.get('last_name'),
                                                locationid: urlParams.get('location_id'),
                                                details: details,
                                                stepfour: stepFourState,
                                                steptwo: stepTwoState,
                                                methodone: methodone,
                                                methodtwo: methodtwo
                                            }),
                                            headers: {
                                                "Content-Type": "application/json; charset=utf-8"
                                            }
                                        }
                                    ).then(response => response.blob())
                                        .then(blob => {
                                            var url = window.URL.createObjectURL(blob);
                                            var a = document.createElement('a');
                                            a.href = url;
                                            a.download = `offer.docx`;
                                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                                            a.click();
                                            a.remove();  //afterwards we remove the element again
                                        });


                                }
                                }>
                                Export Cash Offer DOC
                            </button>
                            {/*<button*/}
                            {/*    className={*/}
                            {/*        checked ? "py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ml-6" :*/}
                            {/*            "py-2 px-4  bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ml-6 rounded-lg cursor-not-allowed"*/}
                            {/*    }*/}
                            {/*    onClick={() => {*/}
                            {/*        seterrors("")*/}
                            {/*        if (!checked) {*/}
                            {/*            seterrors("Please agree to the Terms of Use.")*/}


                            {/*            return*/}
                            {/*        }*/}


                            {/*        let methodone = document.getElementById("methodcashtwo")?.innerText;*/}
                            {/*        let methodtwo = document.getElementById("methodcashone")?.innerText;*/}

                            {/*        fetch('https://test.getgroupleads.com/api/pdf/pdfterter', {*/}
                            {/*                method: "POST",*/}
                            {/*                body: JSON.stringify({*/}
                            {/*                    contactid: urlParams.get('contact_id'),*/}

                            {/*                    email: urlParams.get('email'),*/}
                            {/*                    phone: urlParams.get('phone'),*/}
                            {/*                    full_name: urlParams.get('full_name'),*/}
                            {/*                    first_name: urlParams.get('first_name'),*/}
                            {/*                    last_name: urlParams.get('last_name'),*/}
                            {/*                    locationid: urlParams.get('location_id'),*/}
                            {/*                    details: details,*/}
                            {/*                    stepfour: stepFourState,*/}
                            {/*                    steptwo: stepTwoState,*/}
                            {/*                    methodone: methodone,*/}
                            {/*                    methodtwo: methodtwo*/}
                            {/*                }),*/}
                            {/*                headers: {*/}
                            {/*                    "Content-Type": "application/json; charset=utf-8"*/}
                            {/*                }*/}
                            {/*            }*/}
                            {/*        ).then(response => response.blob())*/}
                            {/*            .then(blob => {*/}
                            {/*                var url = window.URL.createObjectURL(blob);*/}
                            {/*                var a = document.createElement('a');*/}
                            {/*                a.href = url;*/}
                            {/*                a.download = `offer.pdf`;*/}
                            {/*                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox*/}
                            {/*                a.click();*/}
                            {/*                a.remove();  //afterwards we remove the element again*/}
                            {/*            });*/}


                            {/*    }}*/}

                            {/*>*/}

                            {/*    Export Cash Offer PDF*/}
                            {/*</button>*/}
                        </>
                        :
                        <button
                            className="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                            onClick={ NextHandler  }>
                           Save And Next
                        </button>

                    }


                </div>

            </div>

        </div>
    )
}
