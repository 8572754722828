import * as React from 'react';
import {Input} from "./Form/Input";
import {useEffect, useState} from "react";
import {MoneyInputComas} from "./Form/MoneyInputComas";
import {MoneyInputComasImprovment} from "./Form/MoneyInputComasImprovment";

interface stepFiveContract {
    source_value: { name: string, value: string }[],

}

interface stepFourContract {
    datestate: string,
    date: { month: string, day: string, year: string },
    earnest: string,
    methodOne: string,
    methodTwo: string
    purchase_price: string
}

interface Props {
    ARV: string,
    stepFourState: stepFourContract,
    stepFiveState: stepFiveContract,
    setStepFiveState: (data: stepFiveContract) => void,
    updateARV: (data: string) => void,
    totalestimate: number
    settotalestimate: (data: number) => void,

    equity:number,
    setequity:Function,
    marketrent:number,
    setmarketrent:Function,
    cashflow:number,
    setcashflow:Function,
    optiondeposit:number,
    setoptiondeposit:Function,
    secpriceseller:number,
    setsecpriceseller:Function,
    secoptiondeposit:number,
    setsecoptiondeposit:Function,


    wholesalerent:number,
    setwholesalerent:Function,

    firstyearnumber:number,
    setfirstyearnumber:Function,

    yearnumber:number,
    setyearnumber:Function,


    optionpricetoseller:number,
    setoptionpricetoseller:Function,

};

export function StepFive(props: Props) {
useEffect(()=>{

props.setoptionpricetoseller(((Number(props.ARV) - props.totalestimate) /100) * Number(props.equity))
},[props.equity])


    function commas(number: number) {
        console.log(number);
        if(number===0)
            return '0';
        if(number<0)
            number=number*-1;
        if(number===0)
            return '0';
        let n = number.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        if (n.slice(-2).charAt(0) === ".")
            n = n + '0';
        return n;
    }
    return (
        <div className="flex w-full   space-x-3">
            <div className="flex  flex-col w-full lg:w-3/4 xl:w-3/6   md:m-auto  pl-4 pr-4 ">

                <div className="flex flex-row ">
                    {/*<h1 className="text-lg underline font-bold"> <span >Method 1: ARV x 70% - Repairs - Fee</span></h1> <br/>*/}
                </div>



                <div className="sm:col-span-3 mt-12 mb-2" >

                    <h3 className="text-3xl"> Lease Option Offer</h3> <br/>
                    <div className="c-field c-col-1 c-sml-col-1 c-span-24 c-sml-span-12">
                        <div className="text-lg  font-bold text-blue-600 "><h3><span style={{textDecoration: ""}}>Sandwich Lease Option Offer</span>
                        </h3></div>
                    </div>


                </div>

                <div className=" mt-1 flex flex-col md:flex-row justify-between ">
                    <div className="mb-4">
                        <div className="font-semibold">ARV</div>
                        <div className="">${commas(Number(props.ARV))}</div>

                    </div>
                    <div className="mb-4">
                        <div className="font-semibold">Repairs</div>
                        <div className="">${props.totalestimate}</div>

                    </div>

                    <div className="mb-4">
                        <div className="font-semibold">ARV - Repairs ="As-Is" Value</div>
                        <div className="">$ {commas(Number(props.ARV) - props.totalestimate)}</div>

                    </div>

                </div>


                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Min % Equity For SLO
                            </label>

                        </div>


                        <div className="mt-1">
                            <select
                                value={props.equity}
                                onChange={(e) => {
                                    props.setequity(Number(e.target.value))
                                }}


                                className="block w-52 mb-2 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                            >
                                <option value={0}>0%</option>
                                <option value={5}>5%</option>
                                <option value={6}>6%</option>
                                <option value={7}>7%</option>
                                <option value={8}>8%</option>
                                <option value={9}>9%</option>
                                <option value={10}>10%</option>
                                <option value={11}>11%</option>
                                <option value={12}>12%</option>
                                <option value={13}>13%</option>
                                <option value={14}>14%</option>
                                <option value={15}>15%</option>
                                <option value={16}>16%</option>
                                <option value={17}>17%</option>
                                <option value={18}>18%</option>
                                <option value={19}>19%</option>
                                <option value={20}>20%</option>
                                <option value={25}>25%</option>

                            </select>
                            <div className="text-xs"> Commonly 15%</div>
                        </div>


                    </div>

                    <div className="mb-4">
                        <div className="font-semibold">Min Equity Value ($)</div>
                        <div className="">${commas(((Number(props.ARV) - props.totalestimate) /100) * Number(props.equity))}</div>

                    </div>
                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Option Price To Seller
                            <input
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                type="text"
                                   // value={((Number(props.ARV) - props.totalestimate) /100) * Number(props.equity)}
                                   value={props.optionpricetoseller.toFixed(2)}


                            />
                        </label>

                    </div>


                </div>


                <div className=" mt-1 flex flex-col md:flex-row justify-between ">

                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Market Rent



                            <MoneyInputComas value={props.marketrent.toString()}
                                             onChange={(e) => {
                                                 props.setmarketrent(parseInt(e))
                                             }}
                                             onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                        </label>

                    </div>


                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Desired Monthly Cash Flow (%)


                            <select
                                value={props.cashflow}
                                onChange={(e) => {
                                    props.setcashflow(Number(e.target.value))
                                }}

                                id="animals"
                                    className="block w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                    name="animals">
                                <option value={0}>
                                    0%
                                </option>
                                <option value={5}>
                                    5%
                                </option>
                                <option value={10}>
                                    10%
                                </option>
                                <option value={15}>
                                    15%
                                </option>
                                <option value={20}>
                                    20%
                                </option>

                            </select>
                        </label>

                    </div>
                    <div className="mb-4">
                        <div className="font-semibold">Min Monthly Cash Flow ($)
                        </div>
                        <div className="">${commas(props.marketrent/100 * props.cashflow)}
                        </div>

                    </div>


                </div>


                <div className=" mt-1 flex flex-col md:flex-row  space-x-5">
                    <div className="mb-4">
                        <label className="text-gray-700" htmlFor="animals">
                            Rent To Seller

                            <input
                                value={commas(props.marketrent-(props.marketrent/100 * props.cashflow))}



                                type="text"
                                name="address"
                                id="address"
                                autoComplete="address"
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </label>

                    </div>



                    <MoneyInputComasImprovment value={props.optiondeposit.toString()}
                                               onChange={(e) => {
                                                   props.setoptiondeposit(e)
                                               }}
                                               onBlur={()=>{}} label={""} description={""} classes={"w-1/2"} required={""}>Option Deposit Paid To Seller </MoneyInputComasImprovment>
                    <div className="flex-grow">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Number of Years
                            </label>

                        </div>


                        <div className="mt-1">
                            <input
                                type="text"
                                name="last-name"
                                value={props.yearnumber}
                                onChange={(e) => {
                                    props.setyearnumber(Number(e.target.value))
                                }}

                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>


                </div>


                <hr className="mt-12     border-gray-400 "/>
                <div className="text-lg  font-bold text-blue-600  mt-4 mb-2"><h3><span style={{textDecoration: ""}}>Wholesaling Lease Option Offer</span>
                </h3></div>
                <div className=" mt-1 flex flex-col md:flex-row  space-x-5 mb-4">

                    <div className="flex-grow">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Price Seller Wants (Or "As-Is" Value)

                            </label>

                        </div>


                        <div className="">




                            <MoneyInputComas value={props.secpriceseller.toString()}
                                             onChange={(e) => {
                                                 props.setsecpriceseller(e)
                                             }}
                                             onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>
                        </div>
                    </div>


                    <div className="flex-grow">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Market Rent

                            </label>

                        </div>


                        <div className="">

                            <MoneyInputComas value={props.wholesalerent ?props.wholesalerent.toString():'0'}    onChange={(e) => {
                                props.setwholesalerent(Number(e))
                            }} onBlur={()=>{}} label={""} description={""} classes={""} required={""}> </MoneyInputComas>

                        </div>
                    </div>


                </div>


                <div className=" mt-1 flex flex-col md:flex-row  space-x-5">

                    <MoneyInputComasImprovment value={props.secoptiondeposit.toString()}
                                               onChange={(e) => {
                                                   props.setsecoptiondeposit(e)
                                               }}
                                               onBlur={()=>{}} label={"Option Deposit Paid To Seller"} description={""} classes={"w-3/4"} required={""}> Option Deposit Paid To Seller</MoneyInputComasImprovment>

                    <div className="flex-grow">
                        <div className="flex justify-between">

                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Number of Years
                            </label>

                        </div>


                        <div className="mt-1">
                            <input
                                value={props.yearnumber}
                                onChange={(e) => {
                                    props.setyearnumber(Number(e.target.value))
                                }}
                                type="text"
                                name="last-name"

                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>


                </div>


            </div>
        </div>
    );
};