import * as React from 'react';

interface stepTwoContract{
    sellerName:string,
    propertyAddress:string,
    propertyCity:string,
    propertyState:string,
    propertyZipCode:string,
    sellerAddress:string,
    sellerCity:string,
    sellerState:string,
    sellerZipCode:string,
    sellerEmail:string,
}
interface Props {
    nextError:boolean,
    stepTwoState:stepTwoContract,
    setstepTwoState:(data:stepTwoContract)=> void
};

export function StepTwo(props: Props) {
    return (

        <div className="flex w-full   space-x-3">

            <form action="#" method="POST" className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 w-full lg:w-3/4 xl:w-3/6   md:m-auto  pl-4 pr-4  ">
                <div className="sm:col-span-3 mt-4 mb-2" >
                    <div className="block">

                       <h3 className="text-3xl"> Property Information </h3> <br/>


                    </div>

                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Seller Name:

                    </label>
                    <div className="mt-1">
                        <input type="text" id="contact-form-name"

                               value={props.stepTwoState.sellerName}
                               onChange={e=>props.setstepTwoState({...props.stepTwoState,sellerName:e.target.value})}

                               className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                               placeholder="Name"/>
                    </div>
                    {props.stepTwoState.sellerName==="" && props.nextError && <div className="text-red-500">Seller Name required </div>}

                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Property Address:

                    </label>
                    <div className="mt-1">
                        <input
                            value={props.stepTwoState.propertyAddress}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,propertyAddress:e.target.value})}

                            type="text"
                            name="address"
                            id="address"
                            autoComplete="address"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.propertyAddress==="" && props.nextError && <div className="text-red-500">Property Address required </div>}

                </div>


                <div >
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        City:
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.stepTwoState.sellerCity}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,sellerCity:e.target.value})}


                            id="sellerAddress"
                            name="sellerAddress"
                            type="text"
                            autoComplete="sellerAddress"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.sellerCity==="" && props.nextError && <div className="text-red-500">City required </div>}

                </div>

                <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        State
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.stepTwoState.sellerState}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,sellerState:e.target.value})}

                            type="text"
                            name="state"
                            autoComplete="family-name"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                </div>


                <div >
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Zip Code
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.stepTwoState.sellerZipCode}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,sellerZipCode:e.target.value})}


                            id="sellerzipcode"
                            name="zipcode"
                            type="text"
                            autoComplete="zipcode"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.sellerZipCode==="" && props.nextError && <div className="text-red-500">Zip required </div>}

                </div>



                <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Seller Mailing Address:

                    </label>
                    <div className="mt-1">
                        <input

                            value={props.stepTwoState.sellerEmail}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,sellerEmail:e.target.value})}


                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.sellerEmail==="" && props.nextError && <div className="text-red-500">Seller Mailing required </div>}

                </div>

                <div >
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Seller City:
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.stepTwoState.propertyCity}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,propertyCity:e.target.value})}


                            id="city"
                            name="city"
                            type="text"
                            autoComplete="city"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.propertyCity==="" && props.nextError && <div className="text-red-500">Seller City required </div>}

                </div>

                <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Seller State
                    </label>
                    <div className="mt-1">
                        <input
                            value={props.stepTwoState.propertyState}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,propertyState:e.target.value})}


                            type="text"
                            name="state"
                            id="state"
                            autoComplete="family-name"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.propertyState==="" && props.nextError && <div className="text-red-500">Seller State required </div>}

                </div>


                <div >
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Seller Zip Code
                    </label>
                    <div className="mt-1">
                        <input

                            value={props.stepTwoState.propertyZipCode}
                            onChange={e=>props.setstepTwoState({...props.stepTwoState,propertyZipCode:e.target.value})}
                            type="text"

                            id="email"
                            name="zipcode"
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        />
                    </div>
                    {props.stepTwoState.propertyZipCode==="" && props.nextError && <div className="text-red-500">Seller Zip Code required </div>}

                </div>

            </form>

        </div>
    );
};